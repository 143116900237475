<template>
  <div class="wh-space-bottom">
    <b-card>
      <edit-field-header
        :title="title"
        @routerBack="$router.back()"
      />

      <edit-field2
        :fields="fields"
        :row-data="row_data"
        post-api="/kioskpackage/AddPackage"
        finish-router="kioskManagePackage"
        permission="Kiosk_Menu_Installer"
        :submitAction="submitAction"
      >
        <template v-slot:files="scope">
          <slot-file
            ref="slot_files"
            :root-item="scope.rootItem"
            :items="row_data['packageFiles']"
            @updateValues="(value) => row_data['packageFiles'] = value"
          />
        </template>
      </edit-field2>
    </b-card>
  </div>
</template>

<script>
import fields, { reloadFieldData } from './fields'
import EditField2 from '@/components/EditField2'
import EditFieldHeader from '@/components/EditFieldHeader'
import common from '@/common'
import SlotFiles from '@/components/FieldFiles.vue'
import _ from 'lodash'

export default {
  name: 'kioskManagePackageAdd',
  components: {
    EditFieldHeader,
    EditField2,
    fields,
    SlotFile: SlotFiles,
  },

  data() {
    return {
      title: common.getMenuName('kioskManagePackage') + ' - ' + common.getI18n('common.add'),
      fields,
      row_data: {
        packageFiles: [],
        version: '',
        remark: '',
        platform: '',
        isMandatory: true,
      },
    }
  },

  watch: {
    row_data: {
      handler(row_data, _oldValues) {
        // console.log('row_data', row_data)
        // console.log('fields-packageFiles', this.fields.find(f => f.name === 'packageFiles'))

        const fileSuffix = '.' + row_data.packageFiles[0]?.name?.split('.').at(-1);

        const packageConfig = this.fields.find(f => f.name === 'packageFiles')?.slot_config;
        let fileAccept = packageConfig.file_accept;
        if (row_data.platform === 'Windows') fileAccept = '.zip';
        if (row_data.platform === 'Android') fileAccept = '.apk';
        if (packageConfig.file_accept !== fileAccept) {
          packageConfig.placeholder = common.getI18n('kiosk.placeholder')
            .replace('{_type_}', fileAccept);
          packageConfig.file_accept = fileAccept;
        }
        packageConfig.add_disable = (row_data.platform === '');
      },
      deep: true,
    },
  },

  created() {
    reloadFieldData()
  },

  mounted() {
  },

  methods: {
    async submitAction(rowData, api, finishRouter) {
      // 檢查版本號是否重複
      const { data: checkedData } = await this.$requestWehealth({
        method: 'post',
        url: '/kioskupdate/CheckVersion',
        data: {
          'version': rowData.version,
          'platform': rowData.platform
        },
      })

      if (checkedData?.isDuplicated === true) {
        common.showAlert({ title: `${rowData.platform} 平台安裝包版本號 ${rowData.version} 已存在` })
        return
      }

      // 构造含文件流的 formData
      const file = this.row_data.packageFiles[0]
      const _rowData = _.clone(rowData)
      _rowData.packageFile = file
      delete _rowData.packageFiles
      const formData = new FormData()
      for (const key in _rowData) {
        formData.append(key, _rowData[key])
      }

      // 新增安裝包
      await this.$requestWehealth({
        method: 'post',
        url: api,
        data: formData,
      })

      await this.$router.push({ name: finishRouter })
    },
  },
}
</script>

<style scoped>

</style>
